/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

@import 'https://fonts.googleapis.com/css2?family=Jost:wght@500;700;800&display=swap';


@font-face {
  font-family: 'kyri_kapsregular';
  src: url('/fonts/kyri_kaps-webfont.woff2') format('woff2'),
      url('/fonts/kyri_kaps-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
/*
@font-face {
  font-family: 'jost-bold';
  src: url('/fonts/Jost-700-Bold.otf') format('otf');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'jost-medium';
  src: url('/fonts/Jost-500-Medium.otf') format('otf');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'jost-heavy';
  src: url('/fonts/Jost-800-Hevy.otf') format('otf');
  font-weight: normal;
  font-style: normal;

}
*/

* {
    box-sizing: border-box;
  }
  
  html,
  body{
    position: fixed;
  }
  
  html,
  body,
  #root {
    width: 100% !important;
    height: 100%;
    margin: 0;
    padding: 0;
   
  }

  p, h1,h2, h3, h5{
    text-align: center;
    text-shadow: 0 2px 2px #00000073;
  }

  .darker{
      /* Darken on click by 15% (down to 85%) */
      filter: brightness(0.85);
  
  }

  .lighter{
    /* Darken on click by 15% (down to 85%) */
    filter: brightness(1.15);

}

.debugsize{
  padding: 10px 20px;
  background-color: rgba(0,0,0,0.3);
  color: purple;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 25%;
}

  .backPanel{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    display: flex;
    z-index: -10;
    transition: background-color .3s ease-out;
  }

  .debug{
    position: absolute;
    top: 0;
    right: 200px;
    z-index: 999999999;
  }

  .snapscreen{
    width: 100vw;
    height: 100vh;
    opacity: .6;
    background-color: white;
    position: absolute;
    top: 0;
    left:0;
    z-index: 9999;
    pointer-events: none;
  }

  .wpb-game{
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%, 50%);
  }

  .rive{
   // height: 60vh;
   // width: auto;

   height: 100px;
   width: 500px;
   position: absolute;
    top: 73%; 
   left: 50%;
   bottom: 100px;
   transform: translate(-50%, -50%);
   opacity: .6;
   z-index: -1;
   transition: opacity .3s ease-out;
  }

  


  .introMessage{
    font-family: 'kyri_kapsregular', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  //  padding: 30px;
    position: absolute;
    /*
    top: 50%;
    left: 50%;
   // width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    width: 460px;
*/
    top:0;
    left:0;
    width: 100%;
    height: 100%;

    color: white;
    z-index: 99999;



    p, h1, h3{
      text-align: center;
    }

    p{
      line-height: 24px;
    }
/*
    button{
      font-family: 'kyri_kapsregular', sans-serif;
      font-size: 24px;
      color: white;
      background-color: rgb(179, 42, 169);//orange;
      padding: 10px 30px;
      border: none;
      border-radius: 10px;

      &:hover{
        background-color: rgb(238, 155, 2);
      }
    }
    */
}

.ready-intro{
  width: 100%;
  height: auto;
 // border: 1px solid orange;
}

.main-btn{
  z-index: 999999;
  position: absolute;
  bottom: 10%;
  left: 50%;
  max-width: 460px;
}

.main-btn button{
  font-family: 'kyri_kapsregular', sans-serif;
  font-size: 24px;
  color: white;
  background-color: rgb(179, 42, 169);//orange;
  padding: 10px 30px;
  border: none;
  border-radius: 10px;
  min-width: 240px;
  
//  transform: translateX(-50%);
  transform: translate(-50%, 0) scale(1.0);
  transition: all .3s ease-out;
  cursor: pointer;



  &:hover{
    background-color: rgb(238, 155, 2);
    transform: translate(-50%, -2px) scale(1.01);
  }
}

.trailsText > div:not(.instruction){
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.instruction{
  max-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2{
 
     // font-family: 'jost-heavy', Arial, Helvetica, sans-serif;
      font-family: 'Jost', Arial, Helvetica, sans-serif;
      font-weight: 800;
      color: yellow;
      text-transform: uppercase;
      text-align: center;
      font-size: 42px;
      margin: 0;
      line-height: 48px;
    
  }
}

.load-btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.loader {
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-left: 4px solid;
  animation: load 1s infinite linear;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

  
  .preview {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: calc(50% + 20px);
    overflow: hidden;
    border-radius: 8px;
    background: #222;
    z-index: 999;
    border: 6px solid white;
    transform: translate(0%, -50%);
    transition: max-width .5s ease-in-out, max-height .5s ease-in-out .4s;
 

    max-height: 0;
    max-width: 0;
    opacity: 0;
   
    overflow: hidden;

  
  }

  .preview.active{
    max-height: 500px;
    max-width: 500px;
    opacity: 1;
  }


  video {
    max-width: 400px;
    height: auto;
    transform: scale(-1, 1);
  
  }
  .preview canvas {
    transform: scale(-1, 1);
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    width: 100%;
  
    
  }
  
  .guides{
    position: absolute !important;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
  
  .control-panel{
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 9999999;
  }
  
  .stats{
    left: unset!important;
    right:0;
  }
  
  .picker {
    position: relative;
  }
  
  .swatch {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    border: 2px solid #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    margin: 4px 0;

    
  }
  
  .popover {
    position: absolute;
    top: 4px;
    left: calc(100% + 6px);
    border-radius: 9px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    z-index: 9999;
  }
  
  .stroke-value{
    font-size: 12px;
    padding: 10px;
    border: 1px solid white;
    background-color: white;
    border-radius: 6px;
    margin: 4px 0;
    text-align: center;
    font-weight: bold;
    color: #010101;
  
  }


  .sound-button{
    width: 40px;
    height: 40px;
    margin: 4px auto;
    border-radius: 6px;
    border-color: white;
    border-width: 0;
    background-color: #ffffff82;
    cursor: pointer;
    position: absolute;
  //  top: 10px;
  //  right: 10px;

    right: 16px;
    top: 12px;
    z-index: 9999999;
    transform: translateY(0px);
    transition: transform .2s ease-out;
     
    
      svg{
        width: 20px;
        height: 20px;
        margin: 0 auto;
        transform: translateX(-1px);
        //fill: white;
      }
    
      &:hover{
        background-color: #f0ffcb;
        transform: translateY(1px);
      }
    }
    
  
  
  .logoBox{
  
     cursor: pointer;
     position: absolute;
      z-index: 9999999;
      top: 10px;
      left: 10px;
  }
    .logoHolder{
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: overflow-wrap;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
    }
  
      .logoCircle{
        position: absolute;
        width: 80%;
        height: 80%;
        background: purple;
        border-radius: 50%;
        margin-left: -4px;
        cursor: pointer;
        box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.05);
        will-change: width, height;
      }
  
      .letter{
        width: 60px;
        height: 60px;
        background: white;
        border-radius: 100px;
        background-size: contain;
        /* border: 1px solid pink;*/
        will-change: transform, opacity;
      }
    
  
.ControlBar{
    background-color: rgba(0, 0, 0, 0.334);
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 999;
    display: flex;
    flex-direction: row;
    padding: 10px 2px 10px 10px;
    transform: translateY(-50%);
    border-top-right-radius: 8px 8px;
    border-bottom-right-radius: 8px 8px;

    .button-col{
        display: flex;
    flex-direction: column;
    }

    .slider-col{
        padding: 10px 0 5px 4px;
    }

    button{
        width: 40px;
        height: 40px;
        margin: 4px 0;
        border-radius: 6px;
        border-color: rgb(255, 255, 255);
        border-width: 0;
        background-color: white;
        cursor: pointer;

        &:hover{
            background-color: rgb(240, 255, 203);
        }

        svg{
            width: 20px;
            height: 20px;
        }
    }
} 

.status-bar{
  //background-color: rgba(0, 0, 0, 0.334);
  position: absolute;
  left: 50%;
  bottom: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
 // padding: 10px 2px 10px 10px;
  transform: translateX(-50%);
  width: 100%;
  height: initial;
  box-sizing: border-box;
  height: 140px;
  max-width: 760px;

  .message-bar{
    height: 100%;

    h2{
     // font-family: 'jost-heavy', Arial, Helvetica, sans-serif;
      font-family: 'Jost', Arial, Helvetica, sans-serif;
      font-weight: 800;
      color: yellow;
      text-transform: uppercase;
      text-align: center;
      font-size: 42px;
      margin: 0;
      line-height: 48px;
    }
    h5{
      font-family: 'kyri_kapsregular', sans-serif;
      color: white;
      text-transform: uppercase;
      text-align: center;
      margin: 15px 0 0;
      font-size: 18px;
      line-height: 24px;

    }
  }



  .count-bar{
    height: 140px;
    width: 100%;
    transform: opacity 1s ease-out;
  //  background-color: rgba(255,255,0, 0.5);
  //  margin: 10px;

    > div{
      flex-direction: row;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
    }

    .count-number{
      //font-family: 'jost-heavy', Arial, Helvetica, sans-serif;
      font-family: 'Jost', Arial, Helvetica, sans-serif;
      font-weight: 800;
      color: white;
      text-transform: uppercase;
      text-align: center;
      font-size: 42px;
      margin: 0;
      line-height: 48px;
      text-shadow: 0 2px 2px #00000073;

      &.done{
        color: inherit;
       // color: grey; // shade of active color
      }

      &.active{
        color: yellow !important;
      }
    }
  }

  .test-dock{

    width: 100%;
    height: 40px;
    background-color: black;

  }

  .image-dock{

    width: 100%;
    height: 140px;
    display: flex;
  flex-direction: row;
    padding: 0 12%;

      > div{
        width: 100%;
        flex-direction: row;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        align-items: center;
      }

      .trailsText{
        width: 100px;
      }
    /*
      .trailsText > div{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    */
      
    }

    .image-box{
      width: 100px;
      height: 100px;

     border: 2px solid white;
      background-color: blue;
      background-size: cover;

     
   // border: 4px solid #B76EFF;
    box-shadow: inset -9px 17px 12px 0 rgba(86, 91, 90, 0.21);
    border-radius: 11px;
    }

    .finish-dock{

      transform: translateY(-90px);
     
        h2{
          //font-family: 'jost-heavy', Arial, Helvetica, sans-serif;
          font-family: 'Jost', Arial, Helvetica, sans-serif;
      font-weight: 800;
          color: #AF74FF;//yellow;
          text-transform: uppercase;
          text-align: center;
          font-size: 42px;
          margin: 0;
          line-height: 48px;
        }
        h5{
          font-family: 'kyri_kapsregular', sans-serif;
          color: white;
          text-transform: uppercase;
          text-align: center;
          margin: 0 0 15px;
          font-size: 18px;
          line-height: 24px;
    
        }
      
    }

    .finish-dock-links{

      > div{
        width: 100%;
        flex-direction: row;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        align-items: center;
      }

      > div:nth-child(0),
      > div:nth-child(2){
        width: 25%;
      }

      .more-text{
        font-family: 'kyri_kapsregular', sans-serif;
          color: #424C4A;
          text-transform: uppercase;
          max-width: 265px;
          text-align: center;
         // margin: 0 0 15px;
          font-size: 16px;
          line-height: 20px;
        //  text-shadow: 0 2px 2px #00000073;
          text-shadow: 0 2px 2px rgb(0 0 0 / 10%);
          margin-right: 10px;
         
      }

      a.more-link{
       // font-family: 'jost-heavy', Arial, Helvetica, sans-serif;
        font-family: 'Jost', Arial, Helvetica, sans-serif;
      font-weight: 800;
          color: #AF74FF;//yellow;
          text-transform: uppercase;
          text-align: center;
          font-size: 18px;
          margin: 0;
          line-height: 24px;
          

          &:hover{
            color: #ffaf1b;
          }
      }

      img{
        max-width: 30%;
        margin: 0 5px;

      }

    }



   
}

.finish-screen{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: calc( 100vh - 140px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
 // border: 2px solid orange;

  > div{
    width: 100%;
    flex-direction: row;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .btn{
    font-family: 'kyri_kapsregular', sans-serif;
    font-size: 24px;
    color: white;
    background-color: #AF74FF; //rgb(179, 42, 169);//orange;
    padding: 10px 30px;
    border: none;
    border-radius: 10px;
    max-width: 160px;
    
  //  transform: translateX(-50%);
   // transform: translate(-50%, 0) scale(1.0);
    transition: all .3s ease-out;
    cursor: pointer;
  
  
  
    &:hover{
      background-color: rgb(238, 155, 2);
     // transform: translate(-50%, -2px) scale(1.01);
    }
  
  }

  .btn-again{
    transform: translateX(50px);
    color: #AF74FF;// rgb(179, 42, 169);
    background-color: #FFD61B;
    z-index: 2;

    animation-name:fading;
      animation-duration: 1s;
    //  animation-iteration-count: 1;
      animation-delay: 4s;
      animation-fill-mode: forwards;
      opacity:0;
  }

  .btn-save{
    transform: translateX(-50px);

    animation-name:fading;
      animation-duration: 1s;
    //  animation-iteration-count: 1;
      animation-delay: 4.5s;
      animation-fill-mode: forwards;
      opacity:0;
  }

  .preview-white{
    background: white;
    padding: 30px;
    border-radius: 20px;
    
    width: 460px;
    height: 460px;
    max-width: 98vw;
    max-height: 98vw;
    position: relative;

    animation-name: scaling;
  animation-duration: 3s;
 // animation-iteration-count: infinite;
  animation-fill-mode: forwards;
 // animation-delay: 1s;
  transform: scale(0.0);

 

    .mixed{
      position: absolute;
      top: -50px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;

      animation-name: fading;
      animation-duration: 2s;
    //  animation-iteration-count: infinite;
      animation-delay: 3s;
      animation-fill-mode: forwards;
      opacity:0;
    }

    .emotions{
      position: absolute;
      bottom: -35px;
      left: 50%;
      transform: translateX(-50%);

      animation-name:fading;
      animation-duration: 2s;
    //  animation-iteration-count: 1;
      animation-delay: 3.5s;
      animation-fill-mode: forwards;
      opacity:0;
    }


  }

  .preview-image{
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: pink;
    background-size: cover;
    overflow: hidden;

    animation-name: scaling2;
    animation-duration: 3s;
  //  animation-iteration-count: infinite;
    animation-fill-mode: forwards;
   // animation-delay: 2s;
   
  }



}


.gifBtn{
  position: absolute;
  bottom: 0;
  z-index: 999999999999;
}


.bg {
  
  width: 100%;
  height: 100%;
  opacity: 1;
  background-size: cover;
  background-position: center;
  will-change: opacity;
}

@keyframes scaling {
  From {
      transform: scale(0.0);
  }
  To {
      transform: scale(1.0);
  }
}


@keyframes scaling2 {
  0% {
      transform: scale(0.0);
  }
  70% {
      transform: scale(0.0);
  }
  100% {
    transform: scale(1.0);
}
}


@keyframes fading {
  From {
      opacity: 0;
  }
  To {
    opacity: 1;
  }
}

@keyframes sizing {
  From {
      width:0%;
      height: 0%;
  }
  To {
      width: 100%;
      height: 100%;
  }
}

/*/////////////////////////////////////////////////// MOBILE //////////////////*/

@media (max-width: 1024px) and (orientation: portrait){ 

  .main-btn button{
    font-size: 18px;
    padding: 20px;
  }

  .wpb-game {
    top: 12%;
  }

  .rive{

    position: absolute;
    /* top: 73%; */
    left: 50%;
    top: 65%;
    bottom: auto;
    max-width: 100%;
   
   }

   .main-btn{
     bottom: 25%;
     top: 75%;
    bottom: auto;
   }

   .preview{
    left: unset;
    right: 14px;
    top: 14px;
    transform: translate(0%, 0%) scale(.5);
    transform-origin: top right;
   }

   .instruction{
    max-width: 100%;
    width: 100%;
    padding: 40px;
   }
 


  .preview-white{
    padding: 10px;
    border-radius: 10px;
  }

  .status-bar{
    height: 160px;
  }
  

  .status-bar .image-dock .trailsText{

    margin-right: 12px;
  }

  .status-bar .finish-dock-links {

    margin: 0 10px;

    > div{
      width: 100%;
      flex-direction: column;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-evenly;
      align-items: center;

      > div:nth-child(2){
        margin: 8px 0;
      }
    }

    
  }

  .trailsText > div:not(.instruction) {

    >div{
      flex-direction: row;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-evenly;
      align-items: center;
      
    }
  }

  .status-bar .image-dock > div{
    justify-content: center;

    > div{
      margin: 0 2px;
    }
  }

  .finish-screen{
    transform: scale(0.85) translateY(-60px) !important
   }

  .finish-screen > div{
    align-items: flex-end;
  }

  .finish-screen{

    .btn-again{
     // border: 2px solid rgb(240, 15, 56);
      position: absolute;
     // bottom: 80px;
     // left: 20px;
     // bottom: 9%;
     // top: 90%;
     // bottom: unset;
      bottom: 40%;
     // left: 25%;
      transform: translateX(-10%);
      
    }

    .btn-save{
      position: absolute;
     // bottom: 80px;
     // right: 20px;
     // bottom: 9%;
      //top: 90%;
      //bottom: unset;
      bottom: 40%;
     // right: 25%;
      transform: translateX(10%);
    }
  }

  

  .finish-screen .preview-white{
    padding: 24px;


    width: 580px;
    height: 580px;

  
  }

  .status-bar{
   // transform: translate(-50%, 10%) scale(.9);
    transform: translate(-50%, -40%) scale(1);
   }

  .status-bar .finish-dock-links .more-text{
    text-shadow: none;
  }


}

/*////////////  */

@media (max-width: 580px) { 


  .rive{

    top: 58%

   
   }


  .preview-white{

    width: 460px;
    height: 460px;

  }

  .preview{

    transform: translate(0%, 0%) scale(.37);

   }

  .finish-screen{

    .btn-again{
     // border: 2px solid rgb(240, 15, 56);
      position: absolute;
     // bottom: 80px;
     // left: 20px;
     // bottom: 9%;
     // top: 90%;
     // bottom: unset;
      bottom: 30px;
      left: 2%;
      transform: translateX(10%);
      
    }

    .btn-save{
      position: absolute;
     // bottom: 80px;
     // right: 20px;
     // bottom: 9%;
      //top: 90%;
      //bottom: unset;
      bottom: 30px;
      right: 2%;
      transform: translateX(-10%);
    }
  }

  .status-bar{
    transform: translate(-50%, 10%) scale(.9);
  }
}

/*/////////////////////////////////////////////////// MOBILE iPhone SE//////////////////*/
@media (max-width: 375px) { 

  .main-btn {
    bottom: 20%;
    max-width: 90%;
}

.instruction h2{
  font-size: 36px;
}

  .rive{

    position: absolute;
    /* top: 73%; */
    left: 50%;
    bottom: 30%;
    max-width: 95%;
   
   }

   .finish-screen{
    //transform: scale(0.9) translateY(-15px) !important
    transform: scale(0.8) translateY(-60px) !important;
   }

   .finish-screen .preview-white{
    //transform: scale(0.85) translateY(-30px) !important;
   }

   .finish-screen{

    .btn-again{
    //  border: 2px solid green;
      position: absolute;
     // bottom: 80px;
     // left: 20px;
    //  bottom: -2%;
      left: 2%;
      top: calc(90% + 15px);
      bottom: unset;
      transform: translateX(10%);
      
    }

    .btn-save{
      position: absolute;
     // bottom: 80px;
     // right: 20px;
     // bottom: -2%;
      right: 2%;
      top: calc(90% + 15px);
      bottom: unset;
      transform: translateX(-10%);
    }
  }

  .status-bar{
    transform: translate(-50%, 10%) scale(.75)
   }
}